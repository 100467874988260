<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="700px"
        min-width="700px"
        max-width="800px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ updateMode == false ? $t('message.addRemark') : $t('message.updateRemark') + ": " + buyerRemark }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="buyerRemarkForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ $t('message.remark') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    :value="InternalRemark__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="InternalRemark__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#1 ' + $t('message.freeText') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainText]"
                                    :value="InternalRemark__freetext1"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="InternalRemark__freetext1 = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#1 ' + $t('message.freeTextType') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-select
                                :items="types"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="InternalRemark__freetext1_type"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#1 ' + $t('message.freeTextLabel') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainText]"
                                    :value="InternalRemark__freetext1_label"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="InternalRemark__freetext1_label = $event"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#2 ' + $t('message.freeText') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-text-field
                                :rules="[...validationRules.plainText]"
                                :value="InternalRemark__freetext2"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="InternalRemark__freetext2 = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#2 ' + $t('message.freeTextType') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-select
                                :items="types"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="InternalRemark__freetext2_type"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#2 ' + $t('message.freeTextLabel') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-text-field
                                :rules="[...validationRules.plainText]"
                                :value="InternalRemark__freetext2_label"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="InternalRemark__freetext2_label = $event"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#3 ' + $t('message.freeText') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-text-field
                                :rules="[...validationRules.plainText]"
                                :value="InternalRemark__freetext3"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="InternalRemark__freetext3 = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#3 ' + $t('message.freeTextType') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-select
                                :items="types"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                v-model="InternalRemark__freetext3_type"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg3 md6 pl-5 py-1 class="align-center">{{ '#3 ' + $t('message.freeTextLabel') }}</v-flex>
                        <v-flex xs6 lg9 md6 py-1>
                            <v-text-field
                                :rules="[...validationRules.plainText]"
                                :value="InternalRemark__freetext3_label"
                                autocomplete="off"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                solo
                                @change="InternalRemark__freetext3_label = $event"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addInternalRemark()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveInternalRemark()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapActions, mapGetters} from "vuex";
import {isObject} from "Helpers/helpers";

export default {
    name: "AddBuyerRemark",
    props: ['buyerRemarkId','buyerRemark','dialog','updateMode','pageKey'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            types: [
                {value: 'date', text: 'Date'},
                {value: 'text', text: 'Text'},
                {value: 'week', text: 'Week'}
            ],
            validForm: true
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('internalremark', {
            InternalRemark__id: 'InternalRemark.id',
            InternalRemark__title: 'InternalRemark.title',
            InternalRemark__freetext1: 'InternalRemark.freetext1',
            InternalRemark__freetext1_type: 'InternalRemark.freetext1_type',
            InternalRemark__freetext1_label: 'InternalRemark.freetext1_label',
            InternalRemark__freetext1_prefix: 'InternalRemark.freetext1_prefix',
            InternalRemark__freetext2: 'InternalRemark.freetext2',
            InternalRemark__freetext2_type: 'InternalRemark.freetext2_type',
            InternalRemark__freetext2_label: 'InternalRemark.freetext2_label',
            InternalRemark__freetext2_prefix: 'InternalRemark.freetext2_prefix',
            InternalRemark__freetext3: 'InternalRemark.freetext3',
            InternalRemark__freetext3_type: 'InternalRemark.freetext3_type',
            InternalRemark__freetext3_label: 'InternalRemark.freetext3_label',
            InternalRemark__freetext3_prefix: 'InternalRemark.freetext3_prefix',
            InternalRemark__freetext4: 'InternalRemark.freetext4',
            InternalRemark__freetext4_type: 'InternalRemark.freetext4_type',
            InternalRemark__freetext4_label: 'InternalRemark.freetext4_label',
            InternalRemark__freetext4_prefix: 'InternalRemark.freetext4_prefix',
        }, 'statePrefix'),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        ...mapActions('internalremark', {
            createInternalRemark: 'createInternalRemark',
            getInternalRemarkToUpdateById: 'getInternalRemarkToUpdateById',
            resetCurrentState: 'resetCurrentState',
            resetInternalRemarkToUpdate: 'resetInternalRemarkToUpdate',
            updateInternalRemark: 'updateInternalRemark'
        }),
        addInternalRemark() {
            if (this.$refs.buyerRemarkForm.validate()) {
                this.loading.add = true
                this.createInternalRemark()
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.remarkAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.remarkNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.remarkNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.remarkNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveInternalRemark() {
            if (this.$refs.buyerRemarkForm.validate()) {
                this.loading.save = true
                this.updateInternalRemark()
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.remarkUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.remarkNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.remarkNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.remarkNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getInternalRemarkToUpdateById(this.buyerRemarkId)
                        .then( () => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetInternalRemarkToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    }
}
</script>

<style scoped>

</style>